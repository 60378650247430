export const allFormKeys = [
    'active',
    'otherAgency',
    'discipline',
    'firstName',
    'preferredName',
    'lastName',
    'emailAddress',
    'phoneNumber',
    'address',
    'address2',
    'city',
    'state',
    'zipcode',
    'country',
    'virtual',
    'commments',
    'mentorId',
    'supervisorId',
    'burmese',
    'chin',
    'falamChin',
    'hahkaChin',
    'matuChin',
    'tedimChin',
    'zanniatChin',
    'zophieChin',
    'crioulo',
    'filipino',
    'french',
    'frenchCajun',
    'frenchCanadian',
    'frenchCreole',
    'german',
    'punjabi',
    'spanish',
    'vietnamese',
    'oralMotorFeeding',
    'sensoryBasedFeeding',
    'gTubeToOral',
    'foodAversion',
    'motorTransitSwallowingDysfunction',
    'behavior',
    'specifyBehavior',
    'als',
    'signingExactEnglish',
    'handlingNDT',
    'gTubeComfortable',
    'ventComfortable',
    'passyMuirValve',
    'floortime',
    'infantMentalHealthCert',
    'fGRBICohort',
    'aBACert',
    'kinesiotapingExp',
    'kinesiotapingCert',
    'aACComfort',
    'aACExperience',
    'aACCertification',
    'visualScheduleExperience',
    'pECS',
    'hannen',
    'assisstativeTechnology',
    'orthoticsUse',
    'orthoticsAdjustment',
    'wheelchairSupport',
    'wheelchairTraining',
    'wheelchairFitting',
    'aquaticTherapy',
    'hippoTherapy',
    'autismExperience',
    'splinting',
    'weightedVestFitting',
    'primitiveReflexIntegration',
    'additionalExpertise',
    'additionalExpertiseMemo',
    'pictureLink'
]