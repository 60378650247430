import React, { useState } from 'react';
import { Card, Checkbox, Col, Collapse, Form, Row, Select, Input } from 'antd';
import { dropdownSize, inputSize } from '../../../Shared/UI/jsx';
import { brownOptionValues, brownOptions, hancockOptionValues, hancockOptions, johnsonOptionValues, johnsonOptions, lawrenceOptionValues, lawrenceOptions, marionOptionValues, marionOptions, monroeOptionValues, monroeOptions, morganOptionValues, morganOptions, shelbyOptionValues, shelbyOptions } from './CheckboxOptions';

const { Item } = Form;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const ServiceAvailability = ({ form, brownCheckedList, setBrownCheckedList, hancockCheckedList, setHancockCheckedList, johnsonCheckedList, setJohnsonCheckedList, lawrenceCheckedList, setLawrenceCheckedList, marionCheckedList, setMarionCheckedList, monroeCheckedList, setMonroeCheckedList, morganCheckedList, setMorganCheckedList, shelbyCheckedList, setShelbyCheckedList }) => {
//Brown County
const brownCheckAll = brownOptionValues?.length === brownCheckedList?.length;
const brownIndeterminate = brownCheckedList?.length > 0 && brownCheckedList?.length < brownOptions?.length;
const brownOnChange = (list) => {
    setBrownCheckedList(list);
};
const brownOnCheckAllChange = (e) => {
    setBrownCheckedList(e.target.checked ? brownOptionValues : []);
};
//Hancock County
const hancockCheckAll = hancockOptionValues?.length === hancockCheckedList?.length;
const hancockIndeterminate = hancockCheckedList?.length > 0 && hancockCheckedList?.length < hancockOptions?.length;
const hancockOnChange = (list) => {
    setHancockCheckedList(list);
};
const hancockOnCheckAllChange = (e) => {
    setHancockCheckedList(e.target.checked ? hancockOptionValues : []);
};
//Johnson County
const johnsonCheckAll = johnsonOptionValues?.length === johnsonCheckedList?.length;
const johnsonIndeterminate = johnsonCheckedList?.length > 0 && johnsonCheckedList?.length < johnsonOptions?.length;
const johnsonOnChange = (list) => {
    setJohnsonCheckedList(list);
};
const johnsonOnCheckAllChange = (e) => {
    setJohnsonCheckedList(e.target.checked ? johnsonOptionValues : []);
};
//Lawrence County
const lawrenceCheckAll = lawrenceOptionValues?.length === lawrenceCheckedList?.length;
const lawrenceIndeterminate = lawrenceCheckedList?.length > 0 && lawrenceCheckedList?.length < lawrenceOptions?.length;
const lawrenceOnChange = (list) => {
    setLawrenceCheckedList(list);
};
const lawrenceOnCheckAllChange = (e) => {
    setLawrenceCheckedList(e.target.checked ? lawrenceOptionValues : []);
};
//Marion County
const marionCheckAll = marionOptionValues?.length === marionCheckedList?.length;
const marionIndeterminate = marionCheckedList?.length > 0 && marionCheckedList?.length < marionOptions?.length;
const marionOnChange = (list) => {
    setMarionCheckedList(list);
};
const marionOnCheckAllChange = (e) => {
    setMarionCheckedList(e.target.checked ? marionOptionValues : []);
};
//Monroe County
const monroeCheckAll = monroeOptionValues?.length === monroeCheckedList?.length;
const monroeIndeterminate = monroeCheckedList?.length > 0 && monroeCheckedList?.length < monroeOptions?.length;
const monroeOnChange = (list) => {
    setMonroeCheckedList(list);
};
const monroeOnCheckAllChange = (e) => {
    setMonroeCheckedList(e.target.checked ? monroeOptionValues : []);
};
//Morgan County
const morganCheckAll = morganOptionValues?.length === morganCheckedList?.length;
const morganIndeterminate = morganCheckedList?.length > 0 && morganCheckedList?.length < morganOptions?.length;
const morganOnChange = (list) => {
    setMorganCheckedList(list);
};
const morganOnCheckAllChange = (e) => {
    setMorganCheckedList(e.target.checked ? morganOptionValues : []);
};
//Shelby County
const shelbyCheckAll = shelbyOptionValues?.length === shelbyCheckedList?.length;
const shelbyIndeterminate = shelbyCheckedList?.length > 0 && shelbyCheckedList?.length < shelbyOptions?.length;
const shelbyOnChange = (list) => {
    setShelbyCheckedList(list);
};
const shelbyOnCheckAllChange = (e) => {
    setShelbyCheckedList(e.target.checked ? shelbyOptionValues : []);
};

    return(
            <Form
                form={form}
                size="small"
                layout="vertical"
            >
                <Row gutter={[8,8]}>
                    <Card size='small' className='formCard'>
                        <Row>
                            <Col span={8}>
                                <Item name="virtual" label="Virtual">
                                    <Select 
                                        style={dropdownSize}
                                        showSearch
                                        filterOption={(input, option) => 
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="0">Ineligible</Option>
                                        <Option value="1">Open To Virtual</Option>
                                        <Option value="2">Virtual Only</Option>
                                        <Option value="3">No Virtual</Option>
                                    </Select>
                                </Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card size='small' title="Counties" className='formCard'>
                        <Row gutter={[8,8]}>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={brownIndeterminate} onChange={brownOnCheckAllChange} checked={brownCheckAll}>
                                    Brown
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={brownOptions} value={brownCheckedList} onChange={brownOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={hancockIndeterminate} onChange={hancockOnCheckAllChange} checked={hancockCheckAll}>
                                    Hancock
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={hancockOptions} value={hancockCheckedList} onChange={hancockOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={johnsonIndeterminate} onChange={johnsonOnCheckAllChange} checked={johnsonCheckAll}>
                                    Johnson
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={johnsonOptions} value={johnsonCheckedList} onChange={johnsonOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={lawrenceIndeterminate} onChange={lawrenceOnCheckAllChange} checked={lawrenceCheckAll}>
                                    Lawrence
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={lawrenceOptions} value={lawrenceCheckedList} onChange={lawrenceOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={marionIndeterminate} onChange={marionOnCheckAllChange} checked={marionCheckAll}>
                                    Marion
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={marionOptions} value={marionCheckedList} onChange={marionOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={monroeIndeterminate} onChange={monroeOnCheckAllChange} checked={monroeCheckAll}>
                                    Monroe
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={monroeOptions} value={monroeCheckedList} onChange={monroeOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={morganIndeterminate} onChange={morganOnCheckAllChange} checked={morganCheckAll}>
                                    Morgan
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={morganOptions} value={morganCheckedList} onChange={morganOnChange} />
                            </Col>
                            <Col span={24}>
                                <Checkbox style={{fontWeight: 'bold'}} indeterminate={shelbyIndeterminate} onChange={shelbyOnCheckAllChange} checked={shelbyCheckAll}>
                                    Shelby
                                </Checkbox>
                            </Col>
                            <Col span={24}>
                                <CheckboxGroup options={shelbyOptions} value={shelbyCheckedList} onChange={shelbyOnChange} />
                            </Col>
                        </Row>
                    </Card>
                    <Card size='small' title="Languages" className='formCard'>
                        <Row>
                            <Col span={8}>
                                <Item name="burmese" valuePropName="checked">
                                    <Checkbox>
                                        Burmese
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="chin" valuePropName="checked">
                                    <Checkbox>
                                        Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="falamChin" valuePropName="checked">
                                    <Checkbox>
                                        Falam Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="hahkaChin" valuePropName="checked">
                                    <Checkbox>
                                        Hahka Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="matuChin" valuePropName="checked">
                                    <Checkbox>
                                        Matu Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="tedimChin" valuePropName="checked">
                                    <Checkbox>
                                        Tedim Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="zanniatChin" valuePropName="checked">
                                    <Checkbox>
                                        Zanniat Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="zophieChin" valuePropName="checked">
                                    <Checkbox>
                                        Zophie Chin
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="crioulo" valuePropName="checked">
                                    <Checkbox>
                                        Crioulo
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="filipino" valuePropName="checked">
                                    <Checkbox>
                                        Filipino
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="french" valuePropName="checked">
                                    <Checkbox>
                                        French
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="frenchCajun" valuePropName="checked">
                                    <Checkbox>
                                        French Cajun
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="frenchCanadian" valuePropName="checked">
                                    <Checkbox>
                                        French Canadian
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="frenchCreole" valuePropName="checked">
                                    <Checkbox>
                                        French Creolo
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="german" valuePropName="checked">
                                    <Checkbox>
                                        German
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="punjabi" valuePropName="checked">
                                    <Checkbox>
                                        Punjabi
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="spanish" valuePropName="checked">
                                    <Checkbox>
                                        Spanish
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="vietnamese" valuePropName="checked">
                                    <Checkbox>
                                        Vietnamese
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="otherLanguageChecked" valuePropName="checked">
                                    <Checkbox>
                                        Other Language
                                    </Checkbox>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item name="otherLanguage" label="Other Language Name">
                                    <Input />
                                </Item>
                            </Col>
                        </Row>
                    </Card>
                </Row>
            </Form>
    );
}

export default ServiceAvailability;